<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="modal-title flex-space-between">
    <h3>{{options.title}}</h3>
    <ion-button
      (click)="applyTemplate()"
      color="light"
      class="btn-small"
      style="margin: -1px 10px 0 0"
      *ngIf="templates"
    >
      Apply a template
      <ion-icon name="chevron-down-outline"></ion-icon>
    </ion-button>
  </div>
</ion-header>

<ion-content>
  <div class="compose-recipients-wrapper">
    <div *ngIf="!options.hideEmails">
      <div class="recipient-count">
        To:
        <a (click)="toggleShowingRecipients()" *appVar="getRecipientCount() as recipientCount">
          {{recipientCount}} recipient<span *ngIf="recipientCount !== 1">s</span>
          <ion-icon [name]="isShowingRecipients ? 'caret-up-outline' : 'caret-down-outline'" class="add-caret">
          </ion-icon>
        </a>
        <span style="margin-left: 12px" *ngIf="authService.isCompanyAdmin">
          From:
          <a (click)="toggleShowingFrom()"
            >{{creatorName}}<span *ngIf="replyTo"> {{'<' + replyTo + '>' }}</span>
            <ion-icon [name]="isShowingFrom ? 'caret-up-outline' : 'caret-down-outline'" class="add-caret"> </ion-icon>
          </a>
        </span>

        <div class="emails" [hidden]="!isShowingRecipients">
          <div *ngIf="emails.length > 200">
            <i>Too many to display</i>
          </div>
          <div *ngIf="emails.length <= 200">
            <ng-select
              [items]="recipientOptions$ | async"
              bindLabel="name"
              [addTag]="true"
              [multiple]="true"
              [hideSelected]="true"
              [trackByFn]="trackRecipientsByFn"
              [minTermLength]="2"
              [loading]="recipientsLoading"
              typeToSearchText="Please enter 2 or more characters"
              [typeahead]="recipientsInput$"
              [(ngModel)]="emails"
              placeholder="Type a customer name and hit enter"
              class="autocomplete-override"
              [clearable]="false"
              (blur)="recipientSearchBlurred()"
              (search)="recipientsSearched($event)"
            >
            </ng-select>
          </div>

          <ion-button
            class="btn-small"
            fill="clear"
            color="dark"
            (click)="includeInternal = !includeInternal"
            [hidden]="!emails?.length"
            style="margin-left: -10px"
          >
            <ion-icon
              [name]="includeInternal ? 'checkmark-circle' : 'ellipse-outline'"
              class="padded-icon checkbox-icon"
            >
            </ion-icon>
            cc agents on the email
          </ion-button>
        </div>
      </div>
    </div>

    <ion-list>
      <ion-item [hidden]="!isShowingFrom">
        <ion-label position="stacked">From name</ion-label>
        <ion-input
          [(ngModel)]="creatorName"
          placeholder="Who the email is coming from (optional)"
          [readonly]="isReadOnly"
        ></ion-input>
      </ion-item>
      <ion-item [hidden]="!isShowingFrom">
        <ion-label position="stacked">Reply-to email address</ion-label>
        <ion-input [(ngModel)]="replyTo" placeholder="Where replies will go (optional)" [readonly]="isReadOnly">
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Subject</ion-label>
        <ion-input
          [(ngModel)]="subject"
          (ionFocus)="addEmail(); isShowingRecipients = false; isShowingFrom = false;"
          (ionBlur)="onChangeDetected()"
          placeholder="Enter subject (optional)"
          [readonly]="isReadOnly"
        ></ion-input>
      </ion-item>
    </ion-list>
  </div>

  <div class="compose-email-editor">
    <app-unlayer-wrapper
      *ngIf="showingTemplateEditor"
      style="height: 100%; border-top: 1px solid var(--ion-color-step-100)"
      (loaded)="editorLoaded()"
    >
    </app-unlayer-wrapper>

    <angular-editor
      *ngIf="!showingTemplateEditor"
      [placeholder]="'Compose your message'"
      [(ngModel)]="message"
      [config]="editorConfig"
      (click)="addEmail(); isShowingRecipients = false; isShowingFrom = false;"
      (blur)="onChangeDetected()"
    >
    </angular-editor>
  </div>
</ion-content>

<ion-footer>
  <div class="upgrade-feature-banner flex-space-between" *ngIf="!featureEnabled">
    Upgrade to send emails
    <ion-button
      fill="outline"
      color="light"
      class="btn-small"
      [routerLink]="'/upgrade'"
      [queryParams]="{plan: 'growth'}"
      (click)="dismiss()"
    >
      <ion-icon name="arrow-up-circle" class="padded-icon"></ion-icon>
      Upgrade account
    </ion-button>
  </div>
  <div class="footer">
    <ion-button (click)="duplicate()" color="light" [disabled]="isLoading" *ngIf="isReadOnly">
      <ion-icon name="duplicate-outline" class="padded-icon"></ion-icon>
      Copy to new draft
    </ion-button>
    <div style="margin-bottom: 10px" *ngIf="attachments?.length">
      <div class="datetime-picker-label">Attachments</div>
      <ion-button *ngFor="let attachment of attachments; let i = index" (click)="removeAttachment(i)" color="light">
        {{attachment.name}}
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear" color="dark" (click)="addAttachment()">
        <ion-icon name="attach-outline"></ion-icon>
        Add attachment
      </ion-button>
    </div>
    <ion-button
      fill="clear"
      color="dark"
      (click)="addAttachment()"
      *ngIf="!attachments?.length && !isReadOnly"
      style="margin: -5px 0 10px -10px"
    >
      <ion-icon name="attach-outline"></ion-icon>
      Add attachment
    </ion-button>
    <div *ngIf="isScheduled && !isReadOnly" class="datetime-picker-wrapper">
      <div class="datetime-picker-label">Schedule send at</div>
      <span class="flexed">
        <app-datetime-picker
          [initialDate]="getInitialDate()"
          [initialTime]="getInitialTime()"
          (datetimeChanged)="setRunAt($event)"
        >
        </app-datetime-picker>
        <ion-button slot="icon-only" fill="clear" color="dark" class="close-button" (click)="unschedule()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </span>
    </div>
    <div class="flex-space-between" [hidden]="isReadOnly">
      <div>
        <ion-button (click)="send()" color="dark" [disabled]="isLoading || !featureEnabled">
          <span *ngIf="isScheduled">Schedule send</span>
          <span *ngIf="!isScheduled">Send</span>
          <ion-spinner name="crescent" *ngIf="isLoading" style="margin-left: 5px"></ion-spinner>
        </ion-button>
        <ion-button fill="clear" color="dark" (click)="schedule()" [disabled]="isLoading" *ngIf="!isScheduled">
          Schedule
        </ion-button>
        <ion-button fill="clear" color="dark" (click)="sendTest()" [disabled]="isLoading || isSendingTest">
          Send test to myself
          <ion-spinner name="crescent" *ngIf="isSendingTest" style="margin-left: 5px"></ion-spinner>
        </ion-button>
        <ion-button fill="clear" color="dark" (click)="saveDraft()" [disabled]="isLoading || isSavingDraft">
          <span [hidden]="isSavingDraft">Save draft</span>
          <span [hidden]="!isSavingDraft">Saving</span>
          <ion-spinner [hidden]="!isSavingDraft" name="dots" style="margin-left: 5px"></ion-spinner>
        </ion-button>
        <ion-button fill="clear" color="success" [disabled]="true" *ngIf="showDraftSavedMessage">
          <ion-icon name="checkmark-outline"></ion-icon>
          Draft saved just now
        </ion-button>
      </div>
      <div>
        <ion-button fill="clear" color="dark" (click)="discard()" [disabled]="isLoading || isSendingTest">
          <ion-icon name="trash-outline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</ion-footer>
